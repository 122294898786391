import Image from "react-bootstrap/Image";

const MenuButtons = (props) => {
  const { SectionData, pressedMenuButton } = props;

  return (
    <button
      className="card p-0 m-1 d-flex"
      onClick={() =>
        pressedMenuButton(SectionData.id, SectionData.menuSectionTitle)
      }
    >
      <div
        style={{
          backgroundImage: `url(${SectionData.menuSectionImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: 150,
          height: 50,
        }}
      >
        <div className="d-flex justify-content-center align-items-center h-100 bg-black bg-opacity-75 ">
          <p
            style={{
              fontFamily: "Nunito",
              fontWeight: "800",
              fontSize: "14px",
              color: "#ffc107",
              margin: "5px",
            }}
          >
            {SectionData.menuSectionTitle}
          </p>
        </div>
      </div>
    </button>
  );
};
export default MenuButtons;

/* import Image from "react-bootstrap/Image";

const MenuButtons = (props) => {
  const { SectionData, pressedMenuButton } = props;

  return (
    <div
      className="row d-flex m-2 p-2 justify-content-center align-items-center bg-warning">
      <div className="col-6 col-md-6">
       
      </div>
      <div className="col-16 col-md-6">
        <button className="menu-button">
          {SectionData.menuSectionTitle} {SectionData.id}
        </button>
      </div>
    </div>
  );
};
export default MenuButtons;
 */
