import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { MapContainer, TileLayer, Marker, useMap, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowUpCircleFill } from "react-icons/ri";

const greenIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/128/869/869432.png",
  iconSize: [45, 40],
});

const Footer = (props) => {
  const { storeInfo } = props;
  const { t } = useTranslation();
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false);

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.pageYOffset > 300
        ? setScrollButtonVisible(true)
        : setScrollButtonVisible(false);
    };
    window.addEventListener("scroll", handleScrollButtonVisibility);
    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisibility);
    };
  }, []);

  return (
    <div className="p-5 mb-0 bg-warning">
      <Row md={12}>
        <Col
          md={6}
          style={{
            display: "flex", // Set display to flex
            flexDirection: "column", // Items arranged vertically
            // Center items horizontally
            justifyContent: "center", // Center items vertically
            padding: "35px",
          }}
        >
          <h6 style={{ fontFamily: "Arimo" }}>
            {storeInfo.getStoreByStoreID.storeName} -{" "}
            {storeInfo.getStoreByStoreID.storeAddress} -{" "}
            {storeInfo.getStoreByStoreID.storePhone}
          </h6>
          <br></br>
          <h6 style={{ fontFamily: "Arimo" }}>
            {storeInfo.getStoreByStoreID.storeName} -
            {" Fiyatları haber vermeden değiştirme hakkını saklı tutar."}
          </h6>
          <br></br>
          <Row>
            <Col xs={4}>
              <h6 style={{ fontFamily: "Arimo" }}>{t("Day of the week")}</h6>
            </Col>
            <Col xs={4}>{t("Opening")}</Col>
            <Col xs={4}>{t("Closing")}</Col>
          </Row>
          <Row>
            {" "}
            <hr className="my-4" />
          </Row>

          {storeInfo &&
            storeInfo.getStoreByStoreID.storeHours.map((day) => {
              if (day.fullClose === "true") {
                return (
                  <Row key={day.dayOfWeek}>
                    <Col xs={4}>
                      <h6 style={{ fontFamily: "Arimo" }}>
                        {t(day.dayOfWeek)}
                      </h6>
                    </Col>
                    <Col xs={4}>{t("Closed")}</Col>
                    <Col xs={4}>{t("Closed")}</Col>
                  </Row>
                );
              } else {
                return (
                  <Row key={day.dayOfWeek}>
                    <Col xs={4}>
                      <h6 style={{ fontFamily: "Arimo" }}>
                        {t(day.dayOfWeek)}
                      </h6>
                    </Col>
                    <Col xs={4}>{day.openTime}</Col>
                    <Col xs={4}>{day.closeTime}</Col>
                  </Row>
                );
              }
            })}
        </Col>
        <Col
          md={6}
          style={{
            display: "flex", // Set display to flex
            flexDirection: "column", // Items arranged vertically
            alignItems: "center", // Center items horizontally
            justifyContent: "center", // Center items vertically

            paddingTop: "15px",
            paddingBottom: "5px",
          }}
        >
          <Row md={12}>
            <Col md={3}>
              <LeafletMap
                lat={storeInfo.getStoreByStoreID.storeLatitude}
                long={storeInfo.getStoreByStoreID.storeLongitude}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <h6 style={{ fontFamily: "Arimo" }}>
            © All rights reserved. • ADELSHOP - QR CODE Menü bir ADELSOFT
            ürünüdür.
          </h6>
        </Col>
      </Row>
      {scrollButtonVisible && (
        <button
          className="btn btn-black"
          onClick={() => window.scrollTo(0, 0)}
          style={{
            position: "fixed",
            bottom: "50px",
            right: "50px",
            zIndex: "99",
            width: "60px",
            height: "60px",
            zIndex: "9900",
          }}
        >
          <RiArrowUpCircleFill style={{ width: "35px", height: "35px" }} />
        </button>
      )}
    </div>
  );
};

export default Footer;

const LeafletMap = (props) => {
  const { lat, long } = props;
  const [position, setPosition] = useState([lat, long]);

  return (
    <div>
      <MapContainer
        center={position}
        zoom={36}
        scrollWheelZoom={false}
        style={{
          width: "450px",
          height: "300px",
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={greenIcon}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
      ,
    </div>
  );
};
