import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
//require('dotenv').config()
import "./i18n";


const basicauth = () => {
  const basicau = "Basic " + btoa(`${process.env.REACT_APP_BASE_URL}`);
  return basicau;
};

const urlReal = `${process.env.REACT_APP_WEBSITE_NAME}`;

const client = new ApolloClient({
  uri: urlReal,
  cache: new InMemoryCache(),
  credentials: "include",
  headers: {
    Authorization: basicauth(),
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>

    <App />
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
