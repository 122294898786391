import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProductControl from "./ProductControl";

import { useState } from "react";

const MenuSections = (props) => {
  const { SectionData, ProductData, selectedMenuSectionID, sectionTitle } =
    props;

  const [SectionProducts, setSectionProducts] = useState([]);

  if (ProductData.length === 0) return <></>;

  return (
    <div>
      <div className="bg-warning p-1 m-1 h-100 w-90 d-flex justify-content-center align-items-center rounded-top-1">
        <p
          style={{
            fontFamily: "Arimo",
            fontWeight: "700",
            fontSize: "24px",
            margin: "5px",
          }}
        >
          {sectionTitle}
        </p>
      </div>
      <Row>
        {ProductData.map((product) => (
          <Col md={6} key={product.id}>
            <ProductControl productData={product} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default MenuSections;
