import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GET_MENU_BY_ID, GET_MENU_PRODUCTS_BY_MENU_ID } from "./graphqlQueries";
import { useQuery } from "@apollo/client";
import MenuSections from "./components/MenuSections";
import { useState, useEffect } from "react";

import MenuButtons from "./components/MenuButtons";

const MenuBody = (props) => {
  const { storeInfo, selectedMenuSectionID, sectionTitle } = props;

  const menuID = storeInfo.getStoreByStoreID.storeMenuID;

  const [menuSections, setMenuSections] = useState([]);

  const [selectedMenuSectionProducts, setSelectedMenuSectionProducts] =
    useState([]);

  const { loading: productLoading, data: productData } = useQuery(
    GET_MENU_PRODUCTS_BY_MENU_ID,
    {
      variables: {
        menuId: menuID,
      },
    }
  );

  useEffect(() => {
    if (productData) {
      const sortedProducts = [...productData.GetMenuProductsByMenuID].sort(
        (a, b) => a.productOrderNo - b.productOrderNo
      );

      const newshortedProducts = sortedProducts.filter(
        (product) => product.productMenuSectionID === selectedMenuSectionID
      );

      setSelectedMenuSectionProducts(newshortedProducts);
      window.scrollTo(0, 0);
    }

  }, [selectedMenuSectionID, productData]);

  if (productLoading) return <p>Products Loading...</p>;

  return (
    <div>
      <div className="dflex">
        {selectedMenuSectionProducts.length > 0 ? (
          <MenuSections
            SectionData={selectedMenuSectionID}
            ProductData={selectedMenuSectionProducts}
            selectedMenuSectionID={selectedMenuSectionID}
            sectionTitle={sectionTitle}
          />
        ) : (
          <pre>{JSON.stringify(sectionTitle, null, 2)}</pre>
          /*           <MenuSections
            SectionData={selectedMenuSectionID}
            ProductData={productData.GetMenuProductsByMenuID}
            selectedMenuSectionID={selectedMenuSectionID}
          /> */
        )}
      </div>
    </div>
  );
};

export default MenuBody;
