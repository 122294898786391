import { useQuery } from "@apollo/client";
import { GET_STOREINFO } from "./graphqlQueries";
import Header from "./Header";
import Container from "react-bootstrap/Container";
import MenuBody from "./MenuBody";
import Footer from "./Footer";
import classNames from "classnames";
import { Helmet } from "react-helmet";

import { useState, useEffect } from "react";

import "./styles.css";
import Loading from "./Loading";

import MenuButtonsSections from "./MenuButtonsSections";

function App() {
  const storeID = process.env.REACT_APP_API_KEY;

  const { loading, data } = useQuery(GET_STOREINFO, {
    variables: {
      storeId: storeID,
    },
  });

  const [selectedMenuSectionID, setSelectedSectionMenuID] = useState("");

  const [sectionTitle, setSectionTitle] = useState("");

  const pressedMenuButton = (id, sectionTitle) => {
    setSelectedSectionMenuID(id);
    setSectionTitle(sectionTitle);
  };

  if (loading) return <Loading />;

  //const bgURL = data && data.getStoreByStoreID.storeBackgroudURL;

  return (
    <>
      <Header storeInfo={data} />
      <MenuButtonsSections
        storeInfo={data}
        pressedMenuButton={pressedMenuButton}
      />

      <div className="overflow-x-hidden">
        <Helmet>
          <title>{data.getStoreByStoreID.storeName}</title>
        </Helmet>
        <MenuBody
          storeInfo={data}
          selectedMenuSectionID={selectedMenuSectionID}
          sectionTitle={sectionTitle}
        />
        <Footer storeInfo={data} />
      </div>
    </>
  );
}

export default App;
